@use '../../theme/mixins/focus-ring' as *;
@use '../../theme/mixins/link-underline' as *;
@use '../../theme/font-weight' as *;
@use '../../theme/mediaqueries' as *;
@use '../../theme/spacing' as *;
@use '../../theme/opacities' as *;

@mixin text-cta {
  display: inline-block;
  border: none;
  outline: none;
  background-color: transparent;
  color: inherit;
  font: inherit;
  font-size: 1rem;
  font-weight: $font-weight-bold;
  line-height: 1.7;
  word-break: break-word;
  cursor: pointer;
  appearance: none;

  @media (min-width: $mq-medium-and-above) {
    font-size: 1.125rem;
  }
}

@mixin text-cta-text {
  padding: 2px 0 1px;

  @include link-underline;

  @media (min-width: $mq-medium-and-above) {
    padding: 2px 0;
  }
}

.cta {
  @include text-cta;
  @include focus-ring;

  &:disabled,
  &[disabled] {
    opacity: $opacity-disabled-icon;
    pointer-events: none;

    &::before {
      filter: grayscale(100%);
      transform: translate(0, 0);
    }
  }

  .text {
    @include text-cta-text;
  }

  &:active {
    outline: none;
  }

  &[target='_blank'] .text::after {
    content: '\2197';
  }

  &:hover .text {
    @include link-underline-hover;
  }

  &.slim {
    font-size: 0.75rem;

    @media (min-width: $mq-medium-and-above) {
      font-size: 0.875rem;
    }
  }

  &.inline {
    font-size: inherit;
  }

  &.plain .text {
    @include link-underline-none;
  }

  &.thin {
    font-weight: $font-weight-regular;

    .text {
      @include link-underline-thin;
    }

    &:hover .text {
      @include link-underline-hover;
    }
  }
}

.icon {
  position: relative;

  & > * {
    vertical-align: middle;
  }

  + .text {
    margin-left: $spacing-xxs;
  }
}
