@use '@lichtblick/photon/theme/global' as *;

.section {
  display: flex;
  box-sizing: border-box;
  align-items: flex-start;
  min-height: 100vh;
  min-height: 100dvh;

  @media (min-width: $mq-medium-and-above) {
    align-items: center;
    justify-content: center;
  }

  @media (min-width: $mq-large-and-above) {
    padding: $spacing-3xl 0;
  }

  &-bg-faded-aqua {
    @include background-text-color($color-faded-aqua);
  }

  &-bg-faded-berry {
    @include background-text-color($color-faded-berry);
  }

  &-bg-faded-green {
    @include background-text-color($color-faded-green);
  }

  &-bg-faded-smoke {
    @include background-text-color($color-faded-smoke);
  }

  &-bg-light-aqua {
    @include background-text-color($color-light-aqua);
  }

  &-bg-light-berry {
    @include background-text-color($color-light-berry);
  }

  &-bg-light-green {
    @include background-text-color($color-light-green);
  }

  &-bg-light-smoke {
    @include background-text-color($color-light-smoke);
  }

  &-bg-white {
    @include background-text-color($color-white);
  }
}

.wrapper {
  display: grid;
  grid-template-columns: 1fr;
  gap: $spacing-s;
  max-width: #{$mq-large-and-above};
  padding: calc($spacing-s + 2rem) 7rem $spacing-s $spacing-s;

  @media (min-width: $mq-medium-and-above) {
    gap: 20rem;
    grid-template-columns: 1fr 1fr;
    padding: 0 3.5rem;

    &.reversed > :last-child {
      order: -1;
    }
  }
}

.content {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  gap: $spacing-s;
}
