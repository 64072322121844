@use '../../theme/colors' as *;
@use '../../theme/spacing' as *;

.footnote {
  display: flex;
  gap: $spacing-3xs;

  &:not(:last-child) {
    margin-bottom: $spacing-xs;
  }

  sup {
    min-width: 0.5rem;
    font-size: 100%;
  }
}

@each $color-name, $color-value in $colors {
  .color-#{'' + $color-name} {
    color: $color-value;
  }
}
