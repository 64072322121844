@use '@lichtblick/photon/theme/global' as *;

.card {
  display: grid;
  gap: $spacing-xs;
  justify-items: stretch;
  min-width: 18rem;
  padding: $spacing-xs;
  border-radius: $border-radius-xs;
  background: $color-white;

  @media (min-width: $mq-medium-and-above) {
    padding: $spacing-3xs $spacing-s $spacing-xxs;
  }
}

.header {
  display: grid;
  gap: $spacing-xxs;
  justify-items: center;
  padding: $spacing-xs 0 $spacing-xxs;
}

.subheadline {
  color: $color-moss;
  font-size: 1rem;
}

.list {
  padding: 0 $spacing-xs;
  list-style: none;

  li span {
    font-size: 0.75rem;
  }
}

.switch-wrapper {
  display: grid;
  justify-content: center;
  row-gap: $spacing-xxs;
  padding: $spacing-xs 0;
  border-radius: $border-radius-s;
  background: $color-faded-moss;
  color: $color-moss;
}

.switch-input {
  display: flex;
  position: relative;
  align-items: center;
  gap: 2px;
  padding: 2px;
  background: $color-white;
  /* stylelint-disable-next-line order/properties-order */
  border: 1px solid $color-moss;
  border-radius: 10rem;

  &::before {
    content: '';
    display: block;
    position: absolute;
    width: 4rem;
    height: 2rem;
    transition: transform 0.3s;
    border-radius: 10rem;
    background: $color-moss;
  }

  &-right-active::before {
    transform: translateX(calc(100% + 2px));
  }
}

.switch-button {
  z-index: 10;
  width: 4rem;
  height: 2rem;
  transition:
    color 0.3s,
    background 0.1s;
  border-radius: 4rem;
  background: transparent;
  color: $color-moss;
  font-size: 0.75rem;
  font-weight: $font-weight-bold;

  &:hover,
  &:focus {
    background: $color-light-gray;
  }

  &-active {
    color: $color-white;

    &:hover,
    &:focus {
      background: transparent;
    }
  }

  @include focus-ring;
}

.footer {
  display: flex;
  flex-flow: column nowrap;
  gap: $spacing-xs;
  align-items: stretch;
  padding: $spacing-xs 0 $spacing-m;
}

.icon {
  width: 3rem;
  height: 2rem;

  img {
    width: 100%;
    height: 100%;
  }
}
