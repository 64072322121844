@use '../animations' as *;
@use '../colors' as *;
@use '../mediaqueries' as *;

$underline-thin: 1px;
$underline-default: 2px;

@mixin link-underline-base {
  --link-underline-width: #{$underline-thin};

  padding-bottom: calc(1.5 * var(--link-underline-width));
  transition: background-size $small-intro;
  background-repeat: no-repeat;
  background-size:
    0% var(--link-underline-width),
    100% var(--link-underline-width);
  background-position-y: bottom;

  @media (min-width: $mq-small-and-above) {
    --link-underline-width: #{$underline-default};
  }
}

@mixin link-underline {
  background-image: linear-gradient($color-orange 0, $color-orange 100%),
    linear-gradient(currentcolor 0, currentcolor 100%);

  @include link-underline-base;
}

@mixin link-underline-thin {
  @include link-underline;

  @media (min-width: $mq-small-and-above) {
    --link-underline-width: #{$underline-thin};
  }
}

@mixin link-underline-hover {
  background-size: 100% var(--link-underline-width);
}

@mixin link-underline-none {
  background-image: none;
}
